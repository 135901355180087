/**
 * This page is to redirect the /tutorial-capella-cluster-and-data path to the docs site
 */
import React from 'react'

const CapellaClusterData = () => {
  return (
    <div>
      <meta http-equiv="refresh" content="0; url=https://docs.couchbase.com/cloud/get-started/create-account.html"/>
      <link rel="canonical" href="https://docs.couchbase.com/cloud/get-started/create-account.html"/>
      <p>
        This Tutorial has moved. Redirecting...
        <br/>
        <a target="_blank" rel='noreferrer' href="https://docs.couchbase.com/cloud/get-started/create-account.html">Click here if you aren't automatically redirected.</a>
      </p>
    </div>
  )
}

export default CapellaClusterData
